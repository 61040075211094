var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "title": "Markdown Files"
    }
  }, [_c('b-list-group', _vm._l(_vm.files, function (file) {
    return _c('b-list-group-item', {
      key: file.id,
      staticClass: "cursor-pointer",
      attrs: {
        "active": _vm.selectedFile && _vm.selectedFile.id === file.id
      },
      on: {
        "click": function click($event) {
          return _vm.selectFile(file);
        }
      }
    }, [_vm._v(" " + _vm._s(file.name) + " ")]);
  }), 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm.selectedFile ? _c('b-card', {
    attrs: {
      "title": _vm.selectedFile.name
    }
  }, [_c('div', {
    staticClass: "markdown-content",
    domProps: {
      "innerHTML": _vm._s(_vm.renderedMarkdown)
    }
  })]) : _c('b-card', {
    attrs: {
      "title": "Select a file"
    }
  }, [_c('p', [_vm._v("Please select a Markdown file from the list on the left to view.")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }