<template>
    <div class="animated fadeIn">
        <b-card>
            <b-row>
                <b-col md="3">
                    <b-card title="Markdown Files">
                        <b-list-group>
                            <b-list-group-item v-for="file in files" :key="file.id"
                                :active="selectedFile && selectedFile.id === file.id" @click="selectFile(file)"
                                class="cursor-pointer">
                                {{ file.name }}
                            </b-list-group-item>
                        </b-list-group>
                    </b-card>
                </b-col>
                <b-col md="9">
                    <b-card v-if="selectedFile" :title="selectedFile.name">
                        <div v-html="renderedMarkdown" class="markdown-content"></div>
                    </b-card>
                    <b-card v-else title="Select a file">
                        <p>Please select a Markdown file from the list on the left to view.</p>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

export default {
    name: 'MarkdownDocuments',
    data() {
        return {
            files: [], // List of markdown files
            selectedFile: null, // Currently selected file
            isLoading: false // Loading state
        };
    },
    computed: {
        // Convert markdown to HTML
        renderedMarkdown() {
            if (!this.selectedFile || !this.selectedFile.content) {
                return '';
            }
            // return marked(this.selectedFile.content);
            return md.render(this.selectedFile.content);
        }
    },
    mounted() {
        this.fetchFiles(); // Load files on component mount
    },
    methods: {
        // Fetch list of markdown files
        async fetchFiles() {
            this.isLoading = true;
            try {
                const response = await this.$api.get('markdown-documents');
                this.files = response;
            } catch (error) {
                this.$form.makeToastError('Error loading file list');
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },
        // Select and load file content
        async selectFile(file) {
            this.isLoading = true;

            if (!file.content) {
                try {
                    const response = await this.$api.get(`markdown-documents/${file.id}`);

                    this.selectedFile = {
                        ...file,
                        content: response.content
                    };
                } catch (error) {
                    this.$form.makeToastError('Error loading file content');
                    console.error(error);
                } finally {
                    this.isLoading = false;
                }
            } else {
                this.selectedFile = file;
                this.isLoading = false;
            }
        }
    }
};
</script>

<style>
.cursor-pointer {
    cursor: pointer;
}

.markdown-content {
    padding: 10px;
}

.markdown-content h1 {
    font-size: 1.8rem;
}

.markdown-content h2 {
    font-size: 1.5rem;
}

.markdown-content h3 {
    font-size: 1.3rem;
}

.markdown-content pre {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
}

.markdown-content code {
    background-color: #f5f5f5;
    padding: 2px 4px;
    border-radius: 4px;
}
</style>
